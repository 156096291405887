const Icon = (props) => {
  return (
    <svg
      {...props}
      id="announcement-icon"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
    >
      <g id="announcement-icon-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="m150,0c9.02,0,17.2,3.65,23.11,9.54,5.92,5.89,9.58,14.04,9.58,23.04,0,3-.41,5.9-1.16,8.65l71.2,55.11c.43.33.81.7,1.15,1.1h23.42c6.21,0,11.89,2.55,16.01,6.64l.03.03c4.12,4.12,6.66,9.77,6.66,15.96v157.32c0,6.23-2.55,11.88-6.66,15.98-4.11,4.09-9.79,6.64-16.04,6.64H22.66c-6.22,0-11.87-2.54-15.98-6.62C2.43,289.16.03,283.42.03,277.44l-.03-157.37c0-6.23,2.55-11.89,6.66-15.99,4.11-4.09,9.79-6.64,16.04-6.64h23.42c.34-.39.72-.76,1.15-1.1l71.2-55.1c-.76-2.76-1.17-5.66-1.17-8.66,0-9,3.66-17.15,9.58-23.04,5.92-5.9,14.09-9.54,23.12-9.54ZM54.93,162.8c-3.57,0-6.46-2.89-6.46-6.44s2.89-6.44,6.46-6.44h190.14c3.57,0,6.46,2.88,6.46,6.44s-2.89,6.44-6.46,6.44H54.93Zm0,42.37c-3.57,0-6.46-2.88-6.46-6.44s2.89-6.44,6.46-6.44h190.14c3.57,0,6.46,2.88,6.46,6.44s-2.89,6.44-6.46,6.44H54.93Zm28.53,42.37c-3.57,0-6.46-2.88-6.46-6.44s2.89-6.44,6.46-6.44h133.09c3.57,0,6.46,2.89,6.46,6.44s-2.89,6.44-6.46,6.44H83.46ZM228.75,97.44l-54.95-42.53-.69.7c-5.91,5.9-14.09,9.54-23.11,9.54s-17.2-3.65-23.12-9.54l-.68-.7-54.95,42.53h157.5Zm48.55,14.32H22.7c-2.29,0-4.38.94-5.89,2.44-1.56,1.56-2.44,3.67-2.44,5.87v157.37c-.03,2.24.92,4.31,2.44,5.83,1.49,1.49,3.56,2.42,5.85,2.42h254.64c2.28,0,4.38-.94,5.88-2.44s2.45-3.59,2.45-5.86V120.07c0-2.3-.93-4.38-2.42-5.87l-.02-.02c-1.56-1.55-3.68-2.42-5.88-2.42h0ZM150,18.8c3.82,0,7.28,1.54,9.78,4.03,2.6,2.58,4.05,6.09,4.05,9.75,0,3.8-1.55,7.25-4.05,9.74-2.59,2.59-6.11,4.04-9.78,4.03-3.82,0-7.28-1.54-9.78-4.03-2.51-2.5-4.05-5.94-4.05-9.74s1.55-7.26,4.05-9.75c2.59-2.59,6.11-4.04,9.78-4.03Z"
        />
      </g>
    </svg>
  );
};

export default Icon;
