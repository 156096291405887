const Icon = (props) => {
  return (
    <svg
      {...props}
      id="gallery-icon"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 225"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="m227.57,78.28c12.76.01,23.09,10.05,23.08,22.43-.01,12.37-10.37,22.39-23.13,22.38-12.75-.01-23.08-10.04-23.08-22.41-.01-12.36,10.31-22.39,23.05-22.41.02,0,.05,0,.07,0Zm18.29-52.63v-7.11H19.12v157.48h10.82v18.62h-13.19c-4.45,0-8.72-1.69-11.87-4.74C1.74,186.86-.02,182.72,0,178.41V16.32C.01,7.34,7.49.05,16.75,0h231.48c9.29.01,16.81,7.31,16.83,16.32v9.33h-19.19Zm22.37,180.85l-40.05-62.03c-2.7-4.17-8.36-5.42-12.66-2.81-1.17.71-2.16,1.67-2.9,2.81l-18.9,29.53,20.56,32.49h-8.45l-56.17-86.87c-3.14-4.84-9.72-6.3-14.71-3.26-1.36.83-2.5,1.94-3.36,3.26l-54.82,86.87h-7.69V63.54h211.67v142.96h-12.53Zm14.97,18.5H66.72c-9.27-.03-16.79-7.3-16.83-16.29V61.25c.07-8.97,7.57-16.22,16.83-16.25h216.48c9.27.03,16.77,7.31,16.8,16.29v147.55c-.12,8.93-7.6,16.11-16.8,16.15Z" />
      </g>
    </svg>
  );
};

export default Icon;
