const Icon = (props) => {
  return (
    <svg
      {...props}
      id="calendar-icon"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 299.99"
    >
      <g id="calendar-icon-2" data-name="Layer 2">
        <path
          class="cls-1"
          d="m199.22,11.55c0-6.37,6.3-11.55,14.09-11.55s14.09,5.18,14.09,11.55v50.59c0,6.37-6.3,11.55-14.09,11.55s-14.09-5.18-14.09-11.55V11.55Zm-126.93,0c0-6.37,6.3-11.55,14.09-11.55s14.09,5.18,14.09,11.55v50.59c0,6.37-6.3,11.55-14.09,11.55s-14.09-5.18-14.09-11.55c0,0,0-50.59,0-50.59ZM15.62,110.64h268.73v-58.23c-.02-1.89-.77-3.7-2.1-5.05-1.34-1.34-3.16-2.1-5.05-2.1h-25.73c-4.31,0-7.81-3.49-7.82-7.81s3.49-7.81,7.81-7.82h25.76c12.56.04,22.74,10.21,22.78,22.78v224.8c-.04,12.56-10.21,22.74-22.78,22.78H22.78c-12.57-.04-22.75-10.23-22.78-22.8V52.42c.04-12.56,10.21-22.74,22.78-22.78h27.51c4.31,0,7.81,3.49,7.82,7.81s-3.49,7.81-7.81,7.82h-27.52c-1.89.02-3.7.77-5.05,2.1-1.34,1.34-2.1,3.16-2.1,5.05v58.23h0Zm268.73,15.65H15.62v150.9c.02,1.89.77,3.7,2.1,5.05,1.34,1.34,3.16,2.1,5.05,2.1h254.42c1.89-.02,3.7-.77,5.05-2.1,1.34-1.34,2.1-3.16,2.1-5.05V126.29h.01ZM123.22,45.26c-4.31,0-7.81-3.5-7.81-7.82s3.5-7.81,7.81-7.81h52.34c4.31,0,7.81,3.49,7.82,7.81s-3.49,7.81-7.81,7.82h-52.35Z"
        />
      </g>
    </svg>
  );
};

export default Icon;
