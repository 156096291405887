const Icon = (props) => {
  return (
    <svg
      {...props}
      id="broadcasts-icon"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 225"
    >
      <g id="broadcasts-icon-1" data-name="Layer 1">
        <path
          class="cls-1"
          d="m57.59,0h184.81c31.76.1,57.47,21.38,57.59,47.67v129.66c.01,12.62-6.03,24.73-16.8,33.67l-.54.4c-10.72,8.7-25.12,13.58-40.14,13.6H57.59c-15.27,0-29.9-5.05-40.67-14l-.49-.44C5.9,201.69,0,189.76,0,177.33V47.67C.09,21.38,25.82.08,57.59,0Zm74.24,96.45l61.65,35.63c4.31,2.27,5.58,7.01,2.83,10.57-.65.85-1.5,1.58-2.49,2.16l-61.45,35.14c-1.64,1.14-3.72,1.76-5.86,1.76-5.11.03-9.29-3.37-9.33-7.6,0-.02,0-.04,0-.06v-71.36h0c-.07-4.21,4-7.66,9.08-7.72,2-.02,3.96.5,5.57,1.48ZM17.94,53.49h34.18l22.12-38.64h-16.65c-21.87.06-39.58,14.72-39.65,32.82,0,0,0,5.82,0,5.82ZM90.45,14.85l-22.09,38.64h61.91l22.14-38.58h0l-61.96-.06Zm78.13,0l-22.29,38.64h60.3l22.17-38.64h-60.18Zm76.46,0l-22.24,38.64h59.25v-5.82c-.09-17.28-16.28-31.57-37.11-32.76l.1-.06Zm37.11,53.92H17.94v108.56c.04,8.55,4.11,16.74,11.35,22.84l.42.32c7.38,6.16,17.4,9.64,27.88,9.68h184.81c10.31,0,20.21-3.34,27.59-9.3l.39-.36c7.46-6.13,11.66-14.46,11.67-23.16v-108.52l.1-.06Z"
        />
      </g>
    </svg>
  );
};

export default Icon;
